@mixin clearfix() {
    &:before,
    &:after {
        content: " "; // 1
        display: table; // 2
    }
    &:after {
        clear: both;
    }
}

@mixin font-size($sizeValue: 16) {
    font-size: $sizeValue + px;
    font-size: ($sizeValue / 10) + rem;
}

@mixin line-height($sizeValue: 16) {
    line-height: $sizeValue + px;
    line-height: ($sizeValue / 10) + rem;
}

.display-table{
    display: table;
    width: 100%;
    table-layout: fixed !important;

    &.center{
        text-align: center;
    }

    .display-table-cell{
        display: table-cell;
        vertical-align: middle;
    }
}
