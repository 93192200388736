.footer{
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding-top: 40px;

    .footer-bottom{
        padding: 20px 0px;
        background: $wild-sand;
        border-top: 1px solid $mischka;
        color: $dove-gray;
        text-transform: uppercase;
        font-weight: 500;
    }
}
