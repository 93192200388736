body{
    font-family: $open-sans;
    @include font-size(14);
    @include line-height(24);
}

h1,
h2,
h3,
h4,
h5,
h6{
    margin-top: 0px;
    margin-bottom: 30px;
    font-family: $source-sans-pro;
    font-weight: bold;
    //text-transform: uppercase;
}

a{
    color: $bali-hai;
}

@mixin intro-text() {
    @include font-size(18);
    @include line-height(28);
}

.intro-text{
    margin-bottom: 30px;
    @include intro-text;
}
