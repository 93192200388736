@media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .header{
        a{
            padding: 10px 10px;
            @include font-size(14);
        }
        .navigation{

        }
    }
}

@media screen and (min-width: $screen-sm-min) {
    .header{
        .menu-button{
            display: none;
        }
    }

    .mobile-menu{
        display: none;
    }
}

@media screen and (max-width: $screen-xs-max) {
    .panel{
        .panel-body{
            padding: 20px;
        }
    }

    .header{
        .brand-link{
            width: 65px;
            height: 39px;
        }

        .header-top{
            .navigation,
            .header-dropdown,
            .header-login{
                display: none;
            }
        }
        .header-bottom{
            display: none;
        }
    }

    .event-block{
        .event-image{
            max-width: 100%;
        }
    }

    .news-item-block{
        .news-image{
            max-width: 100%;
        }
    }

    .btn{
        display: block;
        width: 100%;

        + .btn{
            margin-top: 15px;
        }
    }

    .table-responsive{
        th{
            display: none;
        }
        tr{
            display: block;
            border-top: 1px solid $alto !important;
        }
        td{
            display: inline-block;
            width: 100%;
            vertical-align: top;
            border: 0px !important;
            text-align: left;

            &[data-title] {
                &:before{
                    display: inline-block;
                    margin-right: 10px;
                    font-weight: bold;
                    vertical-align: top;
                    content: attr(data-title);
                }
            }
        }
    }
}
