.event-block{
    position: relative;
    margin-bottom: 100px;
    padding-bottom: 50px;
    border-bottom: 2px solid $bali-hai;
    @include clearfix;

    .btn{
        position: absolute;
        bottom: 0px;
        right: 0px;
    }

    .event-image{
        max-width: 200px;
    }
}

.event-title{
    margin-bottom: 15px;

    .date{
        display: block;
        font-size: 50%;
        color: $bali-hai;
    }
}

.event-image{
    display: block;
    float: left;
    margin-right: 30px;
    margin-bottom: 10px;
    max-width: 350px;
}

.event{
    &.event-index{

    }

    &.event-view{
        margin-bottom: 50px;
    }
}
