.sponsor-banner{
    padding: 20px 0px;
    background: $white;
    border-top: 1px solid $mischka;
    color: $dove-gray;
    text-transform: uppercase;
    font-weight: 500;

    .sponsor-item{
        display: block;
        padding: 20px 50px;

        img{
            margin-left: auto;
            margin-right: auto;
            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);
        }
    }
}
