.form-control{
    border-radius: 0px;
}

.checkbox,
.radio{

    label{
        padding-left: 0px;
    }
}

input[type="checkbox"]{
    display: none;

    & + span{
        position: relative;
        padding-left: 20px;

        &:before{
            position: absolute;
            left: 0px;
            top: 2px;
            content: '';
            width: 16px;
            height: 16px;
            border: 2px solid  $lima;
        }

        &:after{
            position: absolute;
            left: 4px;
            top: 6px;
            display: none;
            content: '';
            width: 8px;
            height: 8px;
            background: $lima;
        }
    }

    &:checked{
        &  + span{
            &:after{
                display: block;
            }
        }
    }
}

input[type="radio"]{
    display: none;

    & + span{
        position: relative;
        padding-left: 20px;

        &:before{
            position: absolute;
            left: 0px;
            top: 2px;
            content: '';
            width: 16px;
            height: 16px;
            border: 2px solid  $lima;
            border-radius: 50%;
        }

        &:after{
            position: absolute;
            left: 4px;
            top: 6px;
            display: none;
            content: '';
            width: 8px;
            height: 8px;
            background: $lima;
            border-radius: 50%;
        }
    }

    &:checked{
        &  + span{
            &:after{
                display: block;
            }
        }
    }
}
