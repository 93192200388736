.photo,
.photo-album{
    display: block;
    margin-bottom: 30px;
    padding: 20px;
    border: 1px solid $mischka;
    transition: .2s all;

    legend{
        display: block;
        margin-bottom: 0px;
        padding: 10px 10px 0px 10px;
        border: 0px;
        text-align: center;
    }

    &:hover,
    &:focus{
        text-decoration: none;
        background: $wild-sand;
    }
}

.photo{
    img{
        margin-left: auto;
        margin-right: auto;
    }
}

.photo-container{
    opacity: 0;
    transition: .2s all;

    &.loaded{
        opacity: 1;
    }
}

.photos{
    opacity: 0;
    transition: .2s all;

    &.loaded{
        opacity: 1;
    }
}
