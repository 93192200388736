html{
    height: 100%;

    body{
        position: relative;
        min-height: 100%;
        background: lighten($alabaster, 1%);
    }

    &.js{
        body{
            opacity: 0;
            transition: opacity .2s;

            &.loaded{
                opacity: 1;
            }
        }
    }
}

.table-actions{
    display: block;
    margin-left: -4px;
    margin-right: -4px;
    margin-bottom: 0px;
    padding-left: 0px;
    list-style-type: none;
    @include clearfix;

    > li{
        display: block;
        float: left;
        padding-left: 4px;
        padding-right: 4px;
    }
}

.panel-profile{
    text-align: center;

    img{
        margin-bottom: 20px;
        border: 8px solid $lima;
    }

    ul{
        display: block;
        margin-bottom: 0px;
        padding-left: 0px;
        list-style-type: none;

        & > li{
            display: block;
        }
    }

    .profile-name{
        color: $lima;
    }
}

.archive-container{
    margin-bottom: 50px;
    padding: 20px;
    background: $wild-sand;

    h3{
        color: $bali-hai;
        text-transform: uppercase;
    }

    ul{
        margin-bottom: 0px;
        padding-left: 0px;
        list-style-type: none;

        li{
            display: block;
        }

        ul{
            padding-left: 20px;
        }
    }

    a{
        display: block;
        padding: 5px 0px;
        font-weight: bold;
        color: $bali-hai;
        text-transform: uppercase;
    }

    .badge{
        float: right;
    }
}

.badge{
    background: $bali-hai;
}

th,
td{
    //width: 75px;
    text-align: center;
}
