//---------- Grid extension
$screen-xl-min:        1800px;
$screen-lg-max:        ($screen-xl-min - 1) !default;

@include make-grid(xxs);

@media (min-width: $screen-xs-min) {
    @include make-grid(xs);
}

@media (min-width: $screen-sm-min) {
    @include make-grid(sm);
}

@media (min-width: $screen-md-min) {
    @include make-grid(md);
}

@media (min-width: $screen-lg-min) {
    @include make-grid(lg);
}

@media (min-width: $screen-xl-min) {
    @include make-grid(xl);
}
