.mobile-menu{
    position: fixed;
    top: 0px;
    left: -100%;
    z-index: 99;
    height: 100%;
    width: 100%;
    padding-top: 129px;
    padding-bottom: 40px;
    background: $lima-dark;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: .5s all;

    &.active{
        left: 0px;
    }

    .navigation{
        margin-bottom: 30px;

        ul{
            display: block;
            margin-bottom: 0px;
            padding-left: 0px;
            list-style-type: none;
        }

        li{
            display: block;
        }

        a{
            display: block;
            padding: 3px 20px;
            @include font-size(16);
            text-align: center;
            font-weight: bold;
            color: $wild-sand;
            text-transform: uppercase;

            &:hover,
            &:focus{
                text-decoration: none;
            }
        }

        .active{
            a{
                background: darken($lima-dark, 10%);
            }
        }
    }

    .user-navigation{
        ul{
            display: block;
            margin-bottom: 0px;
            padding-left: 0px;
            list-style-type: none;
        }

        li{
            display: block;
        }

        a{
            display: block;
            padding: 3px 20px;
            @include font-size(14);
            text-align: center;
            font-weight: bold;
            color: $wild-sand;
            text-transform: uppercase;

            &:hover,
            &:focus{
                text-decoration: none;
            }
        }

        .active{
            a{
                background: darken($lima-dark, 10%);
            }
        }
    }

    .member-login{
        margin-top: 30px;
        ul{
            display: block;
            margin-bottom: 0px;
            padding-left: 0px;
            list-style-type: none;
        }

        li{
            display: block;
        }

        a{
            display: block;
            padding: 10px 20px;
            color: $wild-sand;
            text-align: center;
            font-weight: bold;
            text-transform: uppercase;
            background: darken($lima-dark, 10%);

            &:hover,
            &:focus{
                text-decoration: none;
            }
        }
    }

    .member-navigation{
        display: block;
        margin-bottom: 0px;
        padding-left: 0px;
        list-style-type: none;
        @include clearfix;

        li{
            display: block;
            width: 50%;
            float: left;
        }

        &.is-admin{
            li{
                width: 33.33%;
            }
        }
    }
}
