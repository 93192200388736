.fc-day{
    cursor: pointer;
}
.fc-event{
    &.active{
        z-index: $z-index-2 !important;
    }
}

.fc-is-unavailable{
    background: $sunset-orange !important;
    color: $white;
    cursor: not-allowed;

    a{
        color: $white;
    }
}

.fc-is-unavailable-by-range{
    background: $alto !important;
    color: $black;
    cursor: not-allowed;
    opacity: 0.3;

    a{
        color: $black;
    }
}

.fc-day-top{
    &.fc-is-unavailable-by-range{
        background: none !important;
    }

    a{
        color: $dove-gray;
    }

    &.fc-is-unavailable{
        a{
            color: $white;
        }

        &.fc-is-unavailable-by-range{
            background: none !important;
            color: $black;
            cursor: not-allowed;
            opacity: 0.3;

            a{
                color: $black;
            }
        }
    }

    &.fc-today{
        position: relative;
        z-index: 20;
        a{
            color: $dove-gray;
        }
    }
}

.fc{
    font-size: 16px !important;
    font-size: 1.6rem !important;

    button{
        height: auto !important;
        padding: 6px 12px !important;
        @include font-size(14);
        border-radius: 0px !important;
        background: $white !important;
        white-space: nowrap;
        box-shadow: none !important;
        border: 1px solid $mischka !important;
    }

    a{
        &:focus,
        &:hover{
            text-decoration: none;
        }
    }

    th,
    td{
        border-color: $mischka !important;
    }

    .fc-day-header{
        padding: 5px;
    }

    .fc-day-top{
        padding: 5px !important;
    }
}

.fc-unthemed{
    .fc-bg{
        .fc-today{
            position: relative;
            z-index: -1;
            background: lighten($boston-blue, 40%);

            a{
                color: $dove-gray;
            }
        }
    }
}

.fc-content-skeleton{

    .fc-day-number{
        position: relative;
        z-index: 10;
    }

    .fc-event-container{
        font-size: inherit;
        line-height: inherit;
    }
}
