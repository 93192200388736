.news-item-block{
    position: relative;
    margin-bottom: 100px;
    padding-bottom: 50px;
    border-bottom: 2px solid $bali-hai;
    @include clearfix;

    .btn{
        position: absolute;
        bottom: 0px;
        right: 0px;
    }

    .news-image{
        max-width: 200px;
    }
}

.news-title{
    margin-bottom: 15px;

    .date{
        display: block;
        font-size: 50%;
        color: $bali-hai;
    }
}

.news-image{
    display: block;
    float: left;
    margin-right: 30px;
    margin-bottom: 10px;
    max-width: 350px;
}

.news{
    &.news-index{

    }

    &.news-view{
        margin-bottom: 50px;
    }
}
