.btn{
    border-radius: 0px;
    white-space: normal;
    transition: .2s all;

    &.btn-primary{
        background: $lima;
        border-color: $lima;

        &:hover,
        &:focus,
        &:focus:active{
            background: $lima-dark;
            border-color: $lima-dark;
        }

        &[disabled]{
            &:hover,
            &:focus,
            &:focus:active {
                background: $lima;
                border-color: $lima;
            }
        }
    }

    &.btn-secondary{
        background: $bali-hai;
        color: $white;
    }

    &.btn-link{
        color: $bali-hai;
    }
}
