.spotlights{
    margin-top: -50px;
    margin-bottom: 100px;
}

.spotlight-inner-container{
    position: relative;
    z-index: 2;
    height: 440px;
    color: $white;
}

.spotlight-item{
    position: relative;
    display: block;
    &:after{
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 1;
        height: 100%;
        width: 100%;
        content: '';
        background: rgba(0,0,0,0.4);
    }

    &:hover,
    &:focus{
        text-decoration: none;
    }
}

.home{
    .match-banner{
        margin: 60px 0px;
        padding: 60px;
        text-align: center;
        background: lighten($bali-hai, 30%);
        border-top: 1px solid $bali-hai;
        border-bottom: 1px solid $bali-hai;

        ul{
            display: block;
            margin-bottom: 0px;
            padding-left: 0px;
            list-style-type: none;
        }

        li{
            display: block;
            & + li{
                margin-top: 60px;
            }
        }
    }
}
