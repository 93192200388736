.page{

    &.page-view{
        .page-attachments{
            margin-top: 40px;

            a{
                display: block;
                margin-bottom: 30px;
            }
        }
    }
}
