$black:                         rgb(0,0,0); //#000000

$astral:                        rgb(54,127,169); //#367FA9
$boston-blue:                   rgb(60,141,188); //#3C8DBC
$bali-hai:                      rgb(138,164,175); //#8AA4AF

$outer-space-light:             rgb(44,59,65); //#2c3b41
$outer-space:                   rgb(34,45,50); //#222D32
$outer-space-dark:              rgb(30,40,44); //#1E282C
$white:                         rgb(255,255,255); //#FFFFFF

$alto:                          rgb(221,211,211); //#DDDDDD

$wild-sand:                     rgb(244,244,244); //#f4f4f4

$mischka:                       rgb(210,214,222); //#D2D6DE

$alabaster:                     rgb(249,249,249); //#F9F9F9

$dove-gray:                     rgb(102,102,102); //#666666

$sunset-orange:                 rgb(255,80,71); //#FF5047

$emerald:                       rgb(56,202,74); //#38CA4A

$lima:                          rgb(138,208,25); //#8ad019
$lima-dark:                     darken($lima, 10%);

$open-sans:                     'Open Sans', sans-serif;
$source-sans-pro:               'Source Sans Pro', sans-serif;
$font-awesome:                  'FontAwesome', sans-serif;

$z-index-1:                     100;
$z-index-2:                     200;
$z-index-3:                     300;
$z-index-4:                     400;
$z-index-5:                     500;
$z-index-6:                     600;
$z-index-7:                     700;
$z-index-8:                     800;
$z-index-9:                     900;
$z-index-10:                    1000;
