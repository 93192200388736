.header{
    position: relative;
    z-index: 100;
    margin-bottom: 50px;
    background: $wild-sand;
    border-bottom: 4px solid $lima-dark;
    box-shadow: none;

    .header-top{
        padding: 20px 0px;
    }

    .header-bottom{
        background: $lima;
        a{
            color: $white;
        }

        .active{
            a{
                color: darken($lima-dark, 10%);
            }
        }
    }

    .brand-link{
        display: inline-block;
        width: 110px;
        height: 65px;
        padding: 0px;
        background: url('../img/logo.png') no-repeat;
        background: url('../img/logo.svg') no-repeat;
        background-size: contain;
        vertical-align: top;
        text-indent: -9999px;
        color: transparent;
    }

    .navigation{
        display: inline-block;
        vertical-align: top;
        margin-top: 26px;
        margin-left: 50px;

        .active{
            a{
                color: darken($bali-hai, 20%);
            }
        }
    }

    ul{
        display: inline-block;
        margin-bottom: 0px;
        padding-left: 0px;
        vertical-align: top;
        list-style-type: none;
        @include clearfix;
    }

    li{
        display: block;
        float: left;
    }

    a{
        display: block;
        padding: 10px 20px;
        font-weight: bold;
        color: $bali-hai;
        text-transform: uppercase;

        &:hover,
        &:focus{
            text-decoration: none;
        }
    }

    .header-login,
    .header-dropdown{
        float: right;
        margin-top: 26px;
    }

    .dropdown{
        ul{
            display: none;
        }

        &.open{
            ul{
                display: block;
            }
        }
    }

    .dropdown-menu{
        left: -50%;
        padding: 0px;
        border-radius: 0px;

        li{
            display: block;
            padding: 0px;
            float: none;
        }

        a{
            display: block;
            padding: 10px 10px;
            text-align: center;
        }
    }

    .menu-button {
        display: inline-block;
        padding: 0px;
        background: none;
        float: right;

        &.active{
            .burger-icon {
                @include burger-to-cross;
            }
        }
    }

    .burger-icon {
        @include burger(25px, 3px, 5px, $lima);
    }
}
