.panel{
    border-radius: 0px !important;

    .panel-heading{
        border-radius: 0px !important;
        text-transform: uppercase;

        a{
            &:hover,
            &:focus{
                text-decoration: none;
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6{
            margin-bottom: 0px;
        }
    }

    .panel-body{
        padding: 30px 40px;
    }

    .panel-footer{
        border-radius: 0px !important;
    }

    &.panel-primary{
        border-color: $lima;

        > .panel-heading{
            margin: -1px;
            padding: 15px 20px;
            background: $lima;
            color: $white;
            border-color: $lima;

            a{
                color: $white;
            }

            & + .panel-collapse{
                > .panel-body{
                    border-color: $lima;
                }
            }
        }

        .panel-primary > .panel-heading

        .panel-body{
            padding: 25px 15px;
        }
    }

    &.panel-seconday{
        > .panel-heading{

        }
    }
}

.panel-navigation{
    margin-top: 30px;
    margin-bottom: 0px;
    padding-left: 0px;
    list-style-type: none;

    li{
        display: block;

        & + li{
            margin-top: 10px;
        }
    }
}
