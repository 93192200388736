.pagination-container{
    text-align: center;
}

.pagination{
    border-radius: 0px;
    > li{
        > a,
        > span{
            margin-left: -2px;
            color: $lima-dark;
            border-width: 2px;

            &:hover,
            &:focus{
                color: $lima-dark;
            }
        }

        &:first-child,
        &:last-child{
            > a,
            > span{
                border-radius: 0px;
            }
        }
    }

    & > .active{
        & > a,
        & > a:hover,
        & > a:focus,
        & > span,
        & > span:hover,
        & > span:focus{
            background: $lima-dark;
            border-color: $lima-dark;
        }
    }
}
