/* Bower packages styling */
// injector
// endinjector

$burger-height: 5px !default;
$burger-gutter: 3px !default;

@import "../../../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "../../../../bower_components/sass-burger/_burger.scss";

@import 'grid-extension';
@import 'mixins';
@import 'variables';
@import 'type';
@import 'buttons';
@import 'panels';
@import 'forms';
@import 'modals';
@import 'utility';
@import 'pagination';

@import 'slick';
@import 'slick-theme';

@import 'header';
@import 'footer';
@import 'fullcalendar';

@import 'general';

@import 'home';
@import 'sponsors';
@import 'news';
@import 'events';
@import 'albums';
@import 'pages';
@import 'calendars';
@import 'competitions';

@import 'responsive';
@import 'mobile';
